<template>
    <div class="goldWallet">
        <div class="balanceBox">
            <div class="balanceBox_top">
                <div class="title">果币余额</div>
                <div class="illustrate">1果币=1元</div>
            </div>
            <div class='balanceBox_center'>
                <svg-icon iconClass="nudeChatCoin" class="svg_icon"></svg-icon>
                <div class="banlance">{{walletInfo.guoBi || 0}}</div>
            </div>
            <div class="balanceBox_bottom">
                <router-link tag="div" to="transaDetails" class="withdraw">账单</router-link>
            </div>
        </div>
        <div class="buyTitle">
            <div class="verticalLine"></div>
            <div>购买金币</div>
        </div>

        <div class="amountGrid">
            <div class="amountBox" :class="{activeAmount: selectIndex == index}" v-for="(item, index) in goldList"
                :key="index" @click="selectItem(index)">
                <div class="priceBox">
                    <div>
                        <div class="goldPrice">
                            <svg-icon iconClass="nudeChatCoin" class="svg_icon"></svg-icon>
                            <div>{{item.goldBase}}</div>
                        </div>
                        <div class="price">{{item.payAmount / 100}}元</div>
                    </div>
                </div>
                <div class="giveAway" v-if="item.desc">{{item.desc}}</div>
            </div>
        </div>
      <div class="paying_btn" @click="clickPaying">立即充值</div>
        <div class="payTips">支付小贴士</div>
        <div class="payTipsBox">
            <p>1、跳转后请及时付款，超时支付无法到账，要重新发起</p>
            <p>2、如多次支付失败，请尝试其他支付方式或稍后再试</p>
            <p>3、支付成功后一般10分钟内到账，超过30分钟未到账请带详细支付截图联系客服</p>
            <p>4、部分安卓手机支付时误报病毒，请选择忽略即可</p>
            <p>5、更多问题请点击查看<span>「常见问题」</span></p>
        </div>
    </div>
</template>

<script>
    import { queryGoldCfg, queryPayMode, rehgSubmit } from '@/api/mine/wallet.js'
    // import { optRecord } from "@/utils/optLog.js";
    export default {
      inject: ['rechgPopShow','saveRechgInfo'],
        data() {
            return {
                walletInfo: {}, // 钱包信息
                goldList: [], // 金币列表
                payModes: [], // 支付方式
                dcInfo: [], // 代充信息
                selectIndex: 0, // 选择金币下标
                dcChannel: '', // 代充h5域名
            }
        },
        created() {
            this.getWalletInfo();
            this.queryGoldCfg();
        },
        methods: {
        async clickPaying(){
            try {
              let req = {
                payAmount: this.goldList[this.selectIndex].payAmount,
                productType: 2, //0-站群 1-棋牌 2-果币
              }
              this.$store.dispatch("app/GET_LOADING", true);
              let ret = await this.$Api(queryPayMode, req);
              this.$store.dispatch("app/GET_LOADING", false);
              if (ret && ret.code == 200) {
                let arr = ret.data.payModesInfo ? ret.data.payModesInfo : [];
                this.payModes = arr.filter(i => {
                  return i.payModeId != 0
                });
                this.dcInfo = ret.data.daiChong;
                this.dcChannel = ret.data.dcH5Domin;
                this.saveRechgInfo({
                  payModes:this.payModes,
                  dcInfo:this.dcInfo,
                  dcH5Domin:this.dcChannel,
                  money:req.payAmount,
                  title:`果币购买`,
                  productType:2,
                  rchgUse:5,
                  useId: this.goldList[this.selectIndex].id,
                })
                if(this.payModes.length == 0) {
                  this.$toast("当前金额无法充值");
                  return;
                }
                this.rechgPopShow(1);
                return;
              }
              this.$toast(ret.tip || "充值失败");
            } catch (e) {
              this.$store.dispatch("app/GET_LOADING", false);
              this.$toast("充值失败");
            }
          },
            // 获取钱包信息
            async getWalletInfo() {
                await this.$store.dispatch('user/setWallet');
                this.walletInfo = this.$store.getters.walletInfo;
            },
            // 查询金币配置
            async queryGoldCfg() {
              this.$store.dispatch("app/GET_LOADING", true);
              try {
                let req = {
                  productType: 2, // 0-站群 1-棋牌 2-果币
                }
                let ret = await this.$Api(queryGoldCfg, req);
                if (ret && ret.code == 200) {
                  this.goldList = ret.data.cfgs;
                  this.selectItem();
                }
                this.$store.dispatch("app/GET_LOADING", false);
              } catch (e) {
                this.$store.dispatch("app/GET_LOADING", false);
              }
            },
            // 选择金币
            async selectItem(index) {
                this.selectIndex = index || 0;
            },
            // 提交订单
            async toPay(payType) {
                // let vipGrap = JSON.parse(sessionStorage.getItem('vipGrap'));
                // if (vipGrap.type == 'video') {
                //   optRecord(3, vipGrap.vInfo, "", undefined, 2);
                // } else {
                //   optRecord(3, {}, "", (vipGrap.type || 'user'), 2);
                // }
                let req = {
                    payAmount: this.goldList[this.selectIndex].payAmount,
                    payMode: payType,
                    productType: 2, // 0-站群 1-棋牌 2-果币
                    rchgUse: 5, // 0-金币 1-vip 2-观影券 3-vip升级卡 4-瓦力棋牌 5-裸聊果币
                    useId: this.goldList[this.selectIndex].id,
                }
                this.$store.dispatch("app/GET_LOADING", true);
                let ret = await this.$Api(rehgSubmit, req);
                if (ret && ret.code == 200) {
                    window.location = ret.data.payUrl;
                } else {
                    this.$toast(ret.tip || '获取支付方式失败');
                }
                this.$store.dispatch("app/GET_LOADING", false);
            },
            dcToPay(dcPayInfo) {
                // let vipGrap = JSON.parse(sessionStorage.getItem('vipGrap'));
                // if (vipGrap.type == 'video') {
                //   optRecord(3, vipGrap.vInfo, "", undefined, 2);
                // } else {
                //   optRecord(3, {}, "", (vipGrap.type || 'user'), 2);
                // }
                // console.log(dcPayInfo)
                this.$store.dispatch("app/GET_LOADING", true);
                let payMoney = this.goldList[this.selectIndex].payAmount / 100;
                let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
                let payInfoModel = JSON.parse(JSON.stringify(dcPayInfo)); // 代充支付类型
                let token = localStorage.getItem('Authorization');
                let productInfo = {
                    id: this.goldList[this.selectIndex].id,
                    type: 0,
                }
                //设置默认值
                if (dcData.limit == 0) {
                    dcData.limit = 500;
                }
                if (payInfoModel.payType.includes(2) && payInfoModel.payType.includes(3)) {
                    if (payMoney > dcData.limit) {
                        payInfoModel.payType = [3];
                    } else {
                        payInfoModel.payType = [2];
                    }
                }
                if (payInfoModel.payType.length >= 3) {
                    if (payMoney > dcData.limit) {
                        payInfoModel.payType = [1, 3];
                    } else {
                        payInfoModel.payType = [1, 2];
                    }
                }
                dcData.traders[0].payInfos = [payInfoModel];
                dcData.productInfo = productInfo;
                dcData.chargeMoney = payMoney;
                dcData.channel = this.dcChannel;
                let models = JSON.stringify(dcData);
                let payUrl = `${this.dcChannel}/?data=${this.encodeBase64(models)}&token=${token}`;
                this.$store.dispatch("app/GET_LOADING", false);
                window.location = payUrl;
            },
            // 转base64
            encodeBase64(str) {
                let base64Str = Buffer.from(str, 'utf-8').toString('base64');
                return base64Str;
            },
            // 支付类型
            handlePayType(payType, isSvg) {
                let nameStr, svgStr;
                switch (payType) {
                    case 1:
                        nameStr = '支付宝';
                        svgStr = 'icon_pay_a1';
                        break;
                    case 2:
                        nameStr = '微信';
                        svgStr = 'icon_pay_w1';
                        break;
                    case 3:
                        nameStr = '银行卡';
                        svgStr = 'pay_kjyl';
                        break;
                    case 4:
                        nameStr = 'USDT';
                        svgStr = 'USDT';
                        break;
                    case 6:
                        nameStr = '快捷银联';
                        svgStr = 'pay_kjyl';
                        break;
                    case 101:
                        nameStr = '支付宝';
                        svgStr = 'icon_pay_a1';
                        break;
                    case 102:
                        nameStr = '微信';
                        svgStr = 'icon_pay_w1';
                        break;
                    case 103:
                        nameStr = '银行卡';
                        svgStr = 'pay_kjyl';
                        break;
                    case 104:
                        nameStr = '信用卡';
                        svgStr = 'pay_kjyl';
                        break;
                    case 105:
                        nameStr = '花呗';
                        svgStr = 'icon_pay_a1';
                        break;
                    case 106:
                        nameStr = '云闪付';
                        svgStr = 'pay_kjyl';
                        break;
                    case 107:
                        nameStr = 'QQ钱包';
                        svgStr = 'pay_kjyl';
                        break;
                    case 108:
                        nameStr = '京东支付';
                        svgStr = 'pay_kjyl';
                        break;
                    default:
                        nameStr = '支付宝';
                        svgStr = 'icon_pay_a1';
                        break;
                }
                return isSvg ? svgStr : nameStr;
            },
        }
    }
</script>

<style lang='scss' scoped>
    .goldWallet {
        height: calc(100vh - 66px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 10px;

        .balanceBox {
            margin-top: 10px;
            height: 162px;
            padding: 15px 10px;
            box-sizing: border-box;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
            // box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .balanceBox_top {
                display: flex;
                justify-content: space-between;
                font-size: 14px;

                .illustrate {
                    color: $vermillion;
                }
            }

            .balanceBox_center {
                display: flex;
                align-items: center;
                font-size: 40px;
                color: #fdc72f;

                .svg_icon {
                    font-size: 30px;
                    margin-right: 3px;
                }
            }

            .balanceBox_bottom {
                display: flex;
                justify-content: end;

                .withdraw {
                  width: 76px;
                  height: 30px;
                  line-height: 30px;
                  font-size: 20px;
                  border-radius: 15px;
                  color: $white1;
                  background: $vermillion;
                  text-align: center;
                }
            }
        }

        .buyTitle {
            height: 25px;
            line-height: 25px;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin: 18px 0 10px;

            .verticalLine {
                width: 3px;
                height: 24px;
                background: $brick;
                margin-right: 3px;
            }
        }

        .amountGrid {
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(auto-fill, 105px);
            grid-gap: 10px;

            .amountBox {
                width: 103px;
                height: 83px;
                border-radius: 6px;
                border: 1px solid #bfbfbf;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // margin-bottom: 14px;

                .priceBox {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;

                    .goldPrice {
                        display: flex;
                        align-items: center;
                        color: $brick;
                    }
                }

                .giveAway {
                    width: 100%;
                    height: 27px;
                    line-height: 27px;
                    font-size: 11px;
                    color: $white1;
                    text-align: center;
                    background: $vermillion;
                }
            }
            .activeAmount {
                background: $vermillion;
                border: 1px solid $vermillion;

                .goldPrice {
                    div {
                        color: $white1;
                    }
                }

                .price {
                    color: rgba(255, 248, 248, 0.8);
                }

                .giveAway {
                    border-top: 1px solid $white1;
                }
            }



        }

        .rechargeTitle {
            font-size: 16px;
            font-weight: 600;
            margin: 22px 0 2.5px;
        }

        .rechargeList {
            // padding-bottom: 7.5px;
            height: 50px;
            // margin: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(139, 139, 139, 0.3);

            .payLogo {
                width: 34px;
                height: 34px;
            }

            .type {
                flex: 2;
                margin-left: 20px;
                font-size: 14px;
            }
        }

        .dcList {
            height: 50px;
            // margin: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(139, 139, 139, 0.3);

            .payLogo {
                width: 34px;
                height: 34px;
            }

            .titleBox {
                flex: 2;
                margin-left: 20px;
                height: 34px;
                font-size: 14px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .typeBox {
                    font-size: 10px;
                    display: flex;
                    align-items: center;

                    .recommend {
                        margin-left: 8px;
                        width: 25px;
                        height: 12.5px;
                    }
                }
            }
        }

        .payTips {
            font-size: 14px;
            padding: 0 10px;
            margin: 8px 0 10px;
        }
        .paying_btn {
            position: absolute;
            left: 50%;
            margin-left: -150px;
            bottom: 150px;
            width: 300px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            color: #FFFFFF;
            font-weight: 600;
            background-image: linear-gradient(90deg, #FC4478 0%, #FF6538 99%, #F54404 100%);
            box-shadow: 0 6px 8px 0 rgba(248,44,44,0.40);
            border-radius: 20px;
          }
        .payTipsBox {
            font-size: 10px;
            line-height: 14px;
            // margin: 0 10px;
            width: 310px;
            color: $greyishBrown;
            padding-bottom: 200px;

            p {
                margin-bottom: 5px;
            }

            span {
                color: $vermillion;
            }
        }
    }
</style>
