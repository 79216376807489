<template>
    <div class="wallet">
        <div class="tabs">
            <div class="title" v-for="(item,index) in tabs" :key="index" :class="{'isActive': activeTab === index}" @click="changeTab(index)">{{item}}</div>
        </div>
        <swiper ref="swiperBox" :options="swiperBoxOptions" class="swiperBox">
            <swiper-slide class="goldItem">
                <GoldWallet v-if="activeTab==0"/>
            </swiper-slide>
            <swiper-slide class="fruitCoinItem">
                <FruitCoinWallet v-if="activeTab==1"/>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import "swiper/dist/css/swiper.css";
    import {
        swiper,
        swiperSlide
    } from 'vue-awesome-swiper';
    import GoldWallet from './GoldWallet.vue';
    import FruitCoinWallet from './FruitWallet.vue';
    export default {
        components: {
            swiper,
            swiperSlide,
            GoldWallet,
            FruitCoinWallet,
        },
        data() {
            return {
                tabs: ["金币钱包", "果币钱包"],
                // 轮播配置
                activeTab: 0,
                swiperBoxOptions: {
                    observer: true,
                    observeParents: true,
                    on: {
                        slideChangeTransitionEnd: () => {
                            this.activeTab = this.$refs.swiperBox.swiper.activeIndex;
                        },
                    },
                }
            }
        },
        mounted() {
            if (this.$route.query.t == 'nudeChat') {
                this.activeTab = 1;
                this.$refs.swiperBox.swiper.slideTo(1);
            }
        },
        methods: {
            changeTab(index) {
                this.$refs.swiperBox.swiper.slideTo(index)
            }
        }
    }
</script>

<style lang='scss' scoped>
    .wallet {
        height: calc(100vh - 44px);

        /deep/ .van-tab--active {
            background: none;
            font-size: 13px;
            color: $vermillion;
        }

        .tabs {
            width: 148px;
            display: flex;
            justify-content: space-between;
            color: $greyishBrown;
            // margin: 3px auto 0;
            margin-top: 4px;
            padding: 0 114px;
            font-size: 13px;
            border-bottom: 1px solid rgba(169, 169, 170, 0.23);
        }

        .isActive {
            color: $vermillion;
            text-align: center;
            // margin-bottom: 4px;
        }

        .isActive::after {
            content: "";
            height: 2px;
            display: block;
            margin-top: 2px;
            background: $vermillion;
            // margin: 0 auto 0;
            // border-bottom: 0.04rem solid $vermillion;
        }

        .noticeBar {
            height: 24px;
            font-size: 12px;
            margin: 7px 0 12px;

            /deep/ .van-notice-bar__left-icon {
                color: $vermillion;
            }
        }
    }
</style>
